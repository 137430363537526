import React, {Component} from 'react';
import CameraPage from "./Assets/Pages/CameraPage";

class App extends Component {
    render() {
        return (
            <div>
              <CameraPage />
            </div>
        );
    }
}

export default App;
import React, {Component, Fragment} from 'react';
import {Container,Row,Col} from "react-bootstrap";
import placeholderimg from '../Images/placeholder.png';
import Webcam from "react-webcam";
import SweetAlert from "react-bootstrap-sweetalert";
class Camera extends Component {
    constructor(props) {
        super(props);
        this.cameraRef=React.createRef();

        this.state={
            capturePhoto:placeholderimg,
            cameraError:false
        }
    }

    onCapture=()=>{
        let Campture = this.cameraRef.current.getScreenshot();
       // alert(Campture);
        this.setState({capturePhoto:Campture});
    }

    onSave=()=>{
        let captureBase = this.state.capturePhoto;
        let a = document.createElement("a");
        a.href=captureBase;
        a.download="CaptureImage.png"
        a.click();
    }

    CameraErrorAlert=()=>{
        if(this.state.cameraError===true){
            return (
                <SweetAlert danger title="Device Camera not working." onConfirm={this.onCameraTryAgain} >
                    Please Allow Device Camera.
                </SweetAlert>
            )
        }
    }
    onCameraTryAgain=()=>{
        window.location.href='/';
    }
    onCameraError=()=>{
        this.setState({cameraError:true})
    }

    render() {
        const videoConstraints = {
            facingMode: "user"
        };

        return (
            <Fragment>
                <Container>
                    <Row className="">
                        <Col md={12}>
                            <div className="logo"><h2>Simple Webcam Capture</h2></div>
                        </Col>
                    </Row>
                    <Row className="shadow-sm bg-white text-center camera_row">
                        <Col md={6} sm={12} lg={6} className="Camera_Col">
                            <Webcam
                                ref={this.cameraRef}
                                onUserMediaError={this.onCameraError}
                                audio={false}
                                screenshotFormat="image/png"
                                videoConstraints={videoConstraints}
                                className="w-100"
                            />
                            <button onClick={this.onCapture} className="btn btn-success cap_btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-camera" viewBox="0 0 16 16">
                                    <path
                                        d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                                    <path
                                        d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                </svg> Capture</button>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="Image_Col">
                            <img className="w-100" alt="Capture Image" src={ this.state.capturePhoto } />
                            <button onClick={this.onSave} className="btn btn-info save_btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-image" viewBox="0 0 16 16">
                                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path
                                        d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                                </svg> Save</button>
                        </Col>
                    </Row>
                </Container>
                {this.CameraErrorAlert()}
            </Fragment>
        );
    }
}

export default Camera;